import CryptoJS from 'crypto-js'

export const getSecrets = () => {
   // const encrypted = CryptoJS.AES.encrypt(
   //     `${process.env.REACT_APP_API_CLIENT_ID_DEV}_${process.env.REACT_APP_API_CLIENT_SECRET_DEV}`,
   //     process.env.REACT_APP_API_ENC_KEY).toString()
   // console.log(`encrypted: ${encrypted}`)

   const decrypted = CryptoJS.AES.decrypt(window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_PROD)
      ? process.env.REACT_APP_API_CLIENT_SECRET_PROD
      : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_PPROD)
         ? process.env.REACT_APP_API_CLIENT_SECRET_PPROD
         : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_QA)
            ? process.env.REACT_APP_API_CLIENT_SECRET_QA
            : process.env.REACT_APP_API_CLIENT_SECRET_DEV,
      process.env.REACT_APP_5T0R3_4K4_KEY).toString(CryptoJS.enc.Utf8)
   // console.log(`decrypted: ${decrypted}`)

   return window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_PROD)
      ? {
         url: `https://vf-${process.env.REACT_APP_AWS_VPC_PROD}-nora-genesys2nuance.auth.us-east-1.amazoncognito.com/oauth2/token`,
         clientId: decrypted.split('_')[0],
         clientSecret: decrypted.split('_')[1],
         scope: `${process.env.REACT_APP_AWS_VPC_PROD}/${process.env.REACT_APP_API_CLIENT_SCOPE}`,
      }
      : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_PPROD)
         ? {
            url: `https://vf-${process.env.REACT_APP_AWS_VPC_PPROD}-nora-genesys2nuance.auth.us-east-1.amazoncognito.com/oauth2/token`,
            clientId: decrypted.split('_')[0],
            clientSecret: decrypted.split('_')[1],
            scope: `${process.env.REACT_APP_AWS_VPC_PPROD}/${process.env.REACT_APP_API_CLIENT_SCOPE}`,
         }
         : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_QA)
            ? {
               url: `https://vf-${process.env.REACT_APP_AWS_VPC_QA}-nora-genesys2nuance.auth.us-east-1.amazoncognito.com/oauth2/token`,
               clientId: decrypted.split('_')[0],
               clientSecret: decrypted.split('_')[1],
               scope: `${process.env.REACT_APP_AWS_VPC_QA}/${process.env.REACT_APP_API_CLIENT_SCOPE}`,
            }
            : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_DEV)
               ? {
                  url: `https://vf-${process.env.REACT_APP_AWS_VPC_DEV}-nora-genesys2nuance.auth.us-east-1.amazoncognito.com/oauth2/token`,
                  clientId: decrypted.split('_')[0],
                  clientSecret: decrypted.split('_')[1],
                  scope: `${process.env.REACT_APP_AWS_VPC_DEV}/${process.env.REACT_APP_API_CLIENT_SCOPE}`,
               }
               : {
                  url: `https://vf-${process.env.REACT_APP_AWS_VPC_DEV}-nora-genesys2nuance.auth.us-east-1.amazoncognito.com/oauth2/token`,
                  clientId: decrypted.split('_')[0],
                  clientSecret: decrypted.split('_')[1],
                  scope: `${process.env.REACT_APP_AWS_VPC_DEV}/${process.env.REACT_APP_API_CLIENT_SCOPE}`,
               }
}

export const resolveHost = () => {
   return window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_PROD)
      ? process.env.REACT_APP_PROD_API
      : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_PPROD)
         ? process.env.REACT_APP_PPROD_API
         : window.location.hostname.includes(process.env.REACT_APP_AWS_VPC_QA)
            ? process.env.REACT_APP_QA_API
            : process.env.REACT_APP_DEV_API;
}